<template>
<div class="container1150">
<header><strong>智慧海绵管理平台</strong></header>
<main>
<div class="img1"></div>
<div class="img2"></div>
</main>
</div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        .img1 {
            // width: 100%;
            width: 1100px;
            height: 500px;
            margin: 0 auto 10px;
            background-image: url("/img/product/20-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img2 {
            // width: 100%;
            width: 1100px;
            height: 500px;
            margin: 0 auto 10px;
            background-image: url("/img/product/20-2.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>